import { Navigate, Route, Routes } from "react-router-dom";
import { GeneralLayout } from "./layout/GeneralLayout";
import { ActivityPage } from "./pages/activity/ActivityPage";
import { LandingPage } from "./pages/landing/LandingPage";
import { RefundPolicyPage } from "./pages/refund-policy/RefundPolicyPage";

export const StackingRoutes = () => {
  return (
    <Routes>
      <Route element={<GeneralLayout />}>
        <Route path="/activity" element={<ActivityPage />}></Route>
        <Route path="/refund-policy" element={<RefundPolicyPage />}></Route>

        <Route path="/" element={<LandingPage />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};
