import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

export const Footer = () => {
  return (
    <Stack
      sx={{
        width: "100%",
        padding: "8px 0",
        background: "rgb(226 120 51)",
        color: "white",
      }}
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => {
            window.open(
              "https://www.facebook.com/happy.corner.hk.fb",
              "_blank"
            );
          }}
        >
          <FacebookIcon sx={{ color: "white", fontSize: "32px" }} />
        </IconButton>
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => {
            window.open("https://www.instagram.com/happy.corner.hk", "_blank");
          }}
        >
          <InstagramIcon sx={{ color: "white", fontSize: "32px" }} />
        </IconButton>
      </Stack>

      <Box>
        <Typography fontSize={"12px"} textAlign={"center"}>
          © Happy Corner HK Limited. All rights reserved.
        </Typography>
      </Box>
    </Stack>
  );
};
