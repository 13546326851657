import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Parallax } from "react-parallax";
import { Animate } from "react-simple-animate";
import HotIcon from "../../../assets/hot-icon.svg";
import RecommendIcon from "../../../assets/recommend-icon.svg";
import { ActivityType } from "../ActivityPage";
import { StyledItem } from "./StyledItem";

export const Activity = ({ activity }: { activity: ActivityType }) => {
  return (
    <StyledItem
      activity={activity}
      bgColor="#FFF"
      hoverBackgroundColor="#fff0e6"
      textColor="#000"
      hoverTextColor="#000"
    />
  );
};
