import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/Footer";
import BG from "../assets/bg.jpg";
import { openSidebarState } from "../utils/recoil-states";
import { Navigation } from "./Navigation";
import { Sidebar } from "./Sidebar";

export const GeneralLayout = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        // height: "100vh",
        width: "100vw",
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // background: "white",
      }}
    >
      {/* <Sidebar drawerWidth={240}></Sidebar> */}
      {/* <Navigation showMenu></Navigation> */}
      {/* <Box flex={1} sx={{ overflow: "auto", padding: {xs: "0 10px", md: "0 30px"} }}> */}
      <Box height="calc(100vh - 70px)" sx={{ overflow: "auto" }}>
        <Outlet />
      </Box>
      <Footer />
      {/* </Box> */}
    </Box>
  );
};
