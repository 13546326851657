import { Divider, Grid, List, ListItem, Typography } from "@mui/material";

export const RefundPolicyPage = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} md={7} maxWidth="600px">
        <Typography variant="h4" sx={{ fontSize: "1.2rem !important" }}>
          活動退款政策(適用於board game活動/咖啡拉花班/各類手作班等)
        </Typography>
        <Typography>
          感謝您參加小角樂舉辦的交友及配對活動，小角樂已為您配對好適合的參加者，請按時出席活動。由於涉及其他參加者的權益、場地和人力等，活動費用之退款政策會根據以下原則處理：
        </Typography>
        <List sx={{ listStyleType: "'-'", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            如因小角樂一方未能如期舉辦活動，例如導師生病、場地出現問題等而無法如期提供服務，會安排為活動改期，如參加者未能出席活動改期之日子，將會安排全數退款。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            活動開始前兩小時內懸掛「8號風球或以上」或「黑色暴雨」，當天活動取消並會安排活動改期。如參加者未能出席活動改期之日子，將會安排全數退款。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            如參加者因事不能出席而申請退款，需要在活動開始前3天提出，小角樂會扣除活動費用之50%作行政開支，餘下50%可作下次參加活動使用。活動開始前3天內之退款申請將不獲接受。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            如參加者因生病而不能出席當天之活動，請附上醫生證明書，小角樂會扣除活動費用之50%作行政開支，餘下50%可作下次參加活動使用。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            如參加者因確診COVID-19而不能出席當天之活動，請在活動開始之前，附上快速抗原測試陽性證明、並在快測棒上寫上名及日期，小角樂會扣除活動費用之50%作行政開支，餘下50%可作下次參加活動使用。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            活動開始後，小角樂不接受參加者因任何原因而缺席活動之退款申請；但倘若由於活動改期而引起，我們將按個別情況作出考慮。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            小角樂有限公司對以上條款及對任何爭議保留最終決定權。
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={11}>
        <Divider />
      </Grid>

      <Grid item xs={10} md={7} maxWidth="600px">
        <Typography variant="h4" sx={{ fontSize: "1.2rem !important" }}>
          戶外活動惡劣天氣安排
        </Typography>
        <Typography>活動開始前兩小時內懸掛：</Typography>
        <List sx={{ listStyleType: "'-'", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>3號風球或以上 或；</ListItem>
          <ListItem sx={{ display: "list-item" }}>黃色暴雨或以上 或；</ListItem>
          <ListItem sx={{ display: "list-item" }}>
            雷暴在西貢10KM內(此項適用於獨木舟活動)
          </ListItem>
        </List>
        <Typography>
          以上情況會安排改期，其他情況我們會視當日實際情況再決定，如有危險絕對不會出海。
        </Typography>
      </Grid>

      <Grid item xs={10} md={7} maxWidth="600px">
        <Typography variant="h4" sx={{ fontSize: "1.2rem !important" }}>
          戶外活動退款政策
        </Typography>
        <Typography>
          感謝您參加小角樂舉辦的交友及配對活動，小角樂已為您配對好適合的參加者，請按時出席活動。由於涉及其他參加者的權益、物資和人力等，活動費用之退款政策會根據以下原則處理：
        </Typography>
        <List sx={{ listStyleType: "'-'", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            如因小角樂一方未能如期舉辦活動，例如導師生病、場地出現問題等而無法如期提供服務，會安排為活動改期，如參加者未能出席活動改期之日子，將會安排全數退款。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            如參加者因事不能出席而申請退款，需要在活動開始前5天提出，小角樂會扣除活動費用之50%作行政開支，餘下50%可作下次參加活動使用。活動開始前3天內之退款申請將不獲接受。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            如參加者因生病而不能出席當天之活動，請附上醫生證明書，小角樂會扣除活動費用之50%作行政開支，餘下50%可作下次參加活動使用。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            如參加者因確診COVID-19而不能出席當天之活動，請在活動開始之前，附上快速抗原測試陽性證明、並在快測棒上寫上名及日期，小角樂會扣除活動費用之50%作行政開支，餘下50%可作下次參加活動使用。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            活動開始後，小角樂不接受參加者因任何原因而缺席活動之退款申請；但倘若由於活動改期而引起，我們將按個別情況作出考慮。
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            小角樂有限公司對以上條款及對任何爭議保留最終決定權。
          </ListItem>
        </List>
      </Grid>

      <Grid xs={10} md={7}>
        <Typography>以上條款更新於2024年8月04日。</Typography>
      </Grid>
    </Grid>
  );
};
