import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { RecoilRoot, useRecoilSnapshot } from "recoil";
import {
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
} from "@mui/material";
import { getDesignTokens } from "./theme";
import { StackingRoutes } from "./routes";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import { ColorModeContext } from "./config/ColorContext";

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug("The following atoms were modified:");
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);
  return null;
}

function App() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <RecoilRoot>
      <DebugObserver />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline></CssBaseline>
          <SnackbarProvider
            classes={{
              containerAnchorOriginBottomLeft: "snackbar",
            }}
          >
            <Router>
              <StackingRoutes></StackingRoutes>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </RecoilRoot>
  );
}

export default App;
