import { createTheme, PaletteMode } from "@mui/material";
import { orange } from "@mui/material/colors";

const defaultTheme = createTheme();

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#d87d3b", // #ff9800
            contrastText: "white",
          },
          secondary: {
            main: "#000",
            contrastText: "#fff",
          },
          info: {
            main: "#fff",
          },
          background: {
            default: "#fff",
          },
          text: {
            secondary: "#838383",
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: orange[500], // #ff9800
            contrastText: "black",
          },
          secondary: {
            main: "#fff",
            contrastText: "#000",
          },
          info: {
            main: "#000",
          },
          background: {
            default: "#000",
          },
          // text: {
          //   secondary: "#838383",
          // },
        }),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Futura,Roboto",
        },
        h1: {
          fontFamily: "Futura,Roboto",
          fontWeight: "bold",
          lineHeight: "unset",
          [defaultTheme.breakpoints.up("xs")]: {
            fontSize: "5.4rem",
            lineHeight: "5rem",
          },
          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: "5.4rem",
            lineHeight: "5.4rem",
          },
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: "3.4rem",
            lineHeight: "3.4rem",
          },
          [defaultTheme.breakpoints.up("lg")]: {
            fontSize: "4.4rem",
            lineHeight: "4.4rem",
          },
          [defaultTheme.breakpoints.up("xl")]: {
            fontSize: "4rem",
            lineHeight: "4.4rem",
          },
        },
        h2: {
          fontFamily: "Futura,Roboto",
          fontWeight: "bold",
          [defaultTheme.breakpoints.up("sm")]: {
            lineHeight: "initial",
          },
          [defaultTheme.breakpoints.up("md")]: {
            lineHeight: "unset",
          },
          [defaultTheme.breakpoints.up("lg")]: {
            lineHeight: "unset",
          },
        },
        h3: {
          fontFamily: "Futura,Roboto",
          fontWeight: "bold",
          lineHeight: "unset",
        },
        h4: {
          fontFamily: "Futura,Roboto",
          fontWeight: "bold",
          lineHeight: "unset",
          [defaultTheme.breakpoints.up("xs")]: {
            fontSize: "1.1rem",
          },
          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: "1.4rem",
          },
          [defaultTheme.breakpoints.up("md")]: {
            fontSize: "1.7rem",
          },
        },
        h5: {
          fontFamily: "Futura,Roboto",
          fontWeight: "bold",
        },
      },
    },
  },
});

// export const defaultTheme = createTheme();

// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: orange[500], // #ff9800
//       contrastText: "white",
//     },
//     secondary: {
//       main: "#000",
//       contrastText: "#fff",
//     },
//     info: {
//       main: "#fff",
//     },
//     background: {
//       default: "#fff",
//     },
//     text: {
//       secondary: "#838383",
//     },
//     // mode: "light",
//     mode: "dark",
//     // dark theme doc: https://mui.com/material-ui/customization/dark-mode/
//   },
//   components: {
//     MuiTypography: {
//       styleOverrides: {
//         root: {
//           fontFamily: "Futura,Roboto",
//         },
//         h1: {
//           fontFamily: "Futura,Roboto",
//           fontWeight: "bold",
//           lineHeight: "unset",
//           [defaultTheme.breakpoints.up("xs")]: {
//             fontSize: "5.4rem",
//             lineHeight: "5rem",
//           },
//           [defaultTheme.breakpoints.up("sm")]: {
//             fontSize: "5.4rem",
//             lineHeight: "5.4rem",
//           },
//           [defaultTheme.breakpoints.up("md")]: {
//             fontSize: "3.4rem",
//             lineHeight: "3.4rem",
//           },
//           [defaultTheme.breakpoints.up("lg")]: {
//             fontSize: "4.4rem",
//             lineHeight: "4.4rem",
//           },
//           [defaultTheme.breakpoints.up("xl")]: {
//             fontSize: "4rem",
//             lineHeight: "4.4rem",
//           },
//         },
//         h2: {
//           fontFamily: "Futura,Roboto",
//           fontWeight: "bold",
//           [defaultTheme.breakpoints.up("sm")]: {
//             lineHeight: "initial",
//           },
//           [defaultTheme.breakpoints.up("md")]: {
//             lineHeight: "unset",
//           },
//           [defaultTheme.breakpoints.up("lg")]: {
//             lineHeight: "unset",
//           },
//         },
//         h3: {
//           fontFamily: "Futura,Roboto",
//           fontWeight: "bold",
//           lineHeight: "unset",
//         },
//         h4: {
//           fontFamily: "Futura,Roboto",
//           fontWeight: "bold",
//           lineHeight: "unset",
//           [defaultTheme.breakpoints.up("xs")]: {
//             fontSize: "1.2rem",
//           },
//           [defaultTheme.breakpoints.up("sm")]: {
//             fontSize: "1.6rem",
//           },
//           [defaultTheme.breakpoints.up("md")]: {
//             fontSize: "2rem",
//           },
//         },
//         h5: {
//           fontFamily: "Futura,Roboto",
//           fontWeight: "bold",
//         },
//       },
//     },
//   },
// });
