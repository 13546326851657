import { Box, Stack, Typography } from "@mui/material";
import { Activity } from "../activity/components/Activity";
import { LandingCarousel } from "./components/LandingCarousel";
import HcIcon from "../../assets/hc-icon.png";
import { StyledItem, StyledItemType } from "../activity/components/StyledItem";

const items: StyledItemType[] = [
  {
    title: "🙋🏼‍♀️ 免費成為會員🙋🏼‍♂️",
    isHot: false,
    isRecommended: false,
    externalLink: "https://forms.gle/b5bfPqwvF8JxL3hw9",
  },
  {
    title: "🔶  報名參加活動 🔶",
    isHot: false,
    isRecommended: false,
    link: "/activity",
  },
];

export const LandingPage = () => {
  return (
    <>
      {/* <LandingCarousel /> */}

      <Stack
        spacing={2}
        alignItems="center"
        pt={5}
        sx={{ margin: { xs: "0 20px", md: "0 30px" }, height: "100%" }}
      >
        <Stack
          mb={2}
          spacing={2}
          alignItems="center"
          maxWidth="600px"
          // maxWidth={{ sm: "500px", md: "700px" }}
        >
          <Box component="img" src={HcIcon} sx={{ width: "25%" }} />
          {/* <Typography variant="h4" textAlign="center" sx={{ width: "100%" }}>
            小角落 Happy Corner
          </Typography> */}

          {items.map((item) => (
            <StyledItem
              styledItem={item}
              bgColor="#FFF"
              hoverBackgroundColor="#fff0e6"
              textColor="#000"
              hoverTextColor="#000"
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
};
