import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Parallax } from "react-parallax";
import { useNavigate } from "react-router-dom";
import { Animate } from "react-simple-animate";
import HotIcon from "../../../assets/hot-icon.svg";
import RecommendIcon from "../../../assets/recommend-icon.svg";
import { ActivityType } from "../ActivityPage";
import { ActivityTitleImg } from "./ActivityTitleImg";

export interface StyledItemType {
  title: string;
  preTitle?: string;
  subTitle?: string;
  activityTitleImg?: any;
  isHot?: boolean;
  isRecommended?: boolean;
  externalLink?: string;
  link?: string;

  location?: string;
  participants?: string;
}

export const StyledItem = ({
  bgColor,
  textColor,
  hoverBackgroundColor,
  hoverTextColor,
  activity,
  styledItem,
}: {
  bgColor?: string;
  textColor?: string;
  hoverBackgroundColor?: string;
  hoverTextColor?: string;
  activity?: ActivityType;
  styledItem?: StyledItemType;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const itemContent = activity ? activity : styledItem ? styledItem : null;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
      onClick={() => {
        if (itemContent?.title) {
          gtag("event", itemContent.title, {
            event_category: process.env.REACT_APP_ENV,
          });
        }

        if (itemContent?.externalLink) {
          window.open(itemContent?.externalLink, "_blank");
        } else if (itemContent?.link) {
          navigate(itemContent.link);
        }
      }}
    >
      <Animate
        play
        start={{ opacity: 0, transform: " translate(0, 1.5em)" }}
        end={{ opacity: 1 }}
        duration={0.5}
      >
        <Grid
          container
          sx={{
            borderRadius: "2px",
            // backgroundImage: `url(${image4})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            boxShadow: "rgba(10, 11, 13, 0.08) 0px 2px 4px 0px",
            width: "100%",
            height: "100%",
            // minHeight: "100px",
            color: textColor ? textColor : "white",
            "& :hover": {
              cursor: "pointer",
            },
          }}
        >
          <Grid
            container
            p={2}
            sx={{
              borderRadius: "2px",
              backgroundColor: bgColor ? bgColor : theme.palette.primary.main,
              // boxSizing: "border-box",
              "&:hover": {
                backgroundColor: hoverBackgroundColor
                  ? hoverBackgroundColor
                  : "white",
                color: hoverTextColor
                  ? hoverTextColor
                  : theme.palette.primary.main,
                // outline: bgColor
                //   ? `2px solid ${bgColor}`
                //   : `2px solid ${theme.palette.primary.main}`,
              },
              "&:focus": {
                backgroundColor: hoverBackgroundColor
                  ? hoverBackgroundColor
                  : "white",
                color: hoverTextColor
                  ? hoverTextColor
                  : theme.palette.primary.main,
                // outline: bgColor
                //   ? `2px solid ${bgColor}`
                //   : `2px solid ${theme.palette.primary.main}`,
              },
            }}
            // backgroundColor={theme.palette.primary}
          >
            <Stack
              direction="row"
              sx={{
                position: "relative",
                transform: "rotate(-25deg)",
                left: "-20px",
                top: "-15px",
              }}
            >
              {itemContent?.isHot && (
                <Box
                  component="img"
                  src={HotIcon}
                  sx={{ width: "30px", position: "absolute" }}
                />
              )}
              {itemContent?.isRecommended && (
                <Box
                  ml={2}
                  component="img"
                  src={RecommendIcon}
                  sx={{ width: "30px", position: "absolute" }}
                />
              )}
            </Stack>

            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <Stack direction="row">
                <Typography
                  fontSize="0.8rem"
                  fontWeight="600"
                  whiteSpace={"pre-line"}
                  textAlign={"center"}
                  // sx={{ marginRight: 1 }}
                >
                  {itemContent?.preTitle ? `${itemContent?.preTitle}` : ""}
                </Typography>

                {itemContent?.activityTitleImg && (
                  <Box mr={1}>
                    <ActivityTitleImg src={itemContent.activityTitleImg} />
                  </Box>
                )}

                <Typography
                  fontSize="0.8rem"
                  fontWeight="600"
                  whiteSpace={"pre-line"}
                  textAlign={"center"}
                >
                  {itemContent?.title}
                </Typography>

                {itemContent?.activityTitleImg && (
                  <Box ml={1}>
                    <ActivityTitleImg src={itemContent.activityTitleImg} />
                  </Box>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Animate>
    </div>
  );
};
